import React from "react"
import { Box, Image, Badge, Grid } from "@chakra-ui/core"
import { Link } from "gatsby"
import Layout from "../../components/layoutSurpriseCustom"
import SEO from "../../components/seo"
import BgImage from "../../images/who-we-are.jpg"
import Jackie from "../../images/surprise-staff/jackie.jpg"
import Nathan from "../../images/surprise-staff/nathan.jpg"
import Cassidy from "../../images/surprise-staff/cassidy.jpg"
import Chad from "../../images/surprise-staff/chad.jpg"
import Rochelle from "../../images/surprise-staff/rochelle.jpeg"
import Tyler from "../../images/surprise-staff/tyler.jpg"
import Debra from "../../images/support-staff/debra.jpg"
import Ed from "../../images/support-staff/ed.jpg"
import Florence from "../../images/support-staff/florence.jpg"
import Andrew from "../../images/surprise-staff/andrew.jpg"
import Michael from "../../images/surprise-staff/michaelfox.jpg"
import Cory from "../../images/surprise-staff/corytafoya.jpg"
import Josh from "../../images/phoenix-staff/josh.jpg"
import Jim from "../../images/surprise-staff/jimgodfrey.jpg"
import Eugene from "../../images/surprise-staff/eugenejohnson.jpg"
import Caroll from "../../images/surprise-staff/carollkey.jpg"
import Louie from "../../images/surprise-staff/LouieProfile.jpg"
import Scott from "../../images/phoenix-staff/scott.jpg"
import Lyle from "../../images/surprise-staff/LyleHarrenstein.jpeg"
import Garrett from "../../images/garrett.jpg"
import Jackson from "../../images/surprise-staff/JacksonNay.jpeg"
import Todd from "../../images/surprise-staff/ToddCarmichael.jpeg"
import Sean from "../../images/surprise-staff/sean.jpg"
import Preston from "../../images/surprise-staff/preston.jpg"
import Kyle from "../../images/surprise-staff/kyle.jpg"
import Jessica from "../../images/surprise-staff/jessica.jpg"
import Alicia from "../../images/surprise-staff/alicia.jpg"
import Krystal from "../../images/surprise-staff/krystal.jpg"
import Adam from "../../images/surprise-staff/adam.jpg"
import Robbie from "../../images/surprise-staff/robbie.jpg"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import PageHeaders from "../../components/pageHeaders"

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
`

function StaffBox({ imageUrl, imageAlt, name, title }) {
  return (
    <Box
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {name}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {title}
        </Box>
      </Box>
    </Box>
  )
}

const OurLeadership = () => (
  <Layout
    ImgSrc={BgImage}
    MainText="Our Leadership"
    SecondText="Staff here at Cross Church Surprise"
  >
    <SEO title="Our Leadership - Surprise" />
    <PageHeaders>Surprise Campus Leadership</PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText></ParagraphText>
    <HeaderText></HeaderText>
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
      gap={10}
    >
      <StaffBox
        imageUrl={Andrew}
        imageAlt="Andrew's Profile Pic"
        name="Andrew Bailey"
        title="Campus Pastor"
      />
      <StaffBox
        imageUrl={Josh}
        imageAlt="Josh's Profile Pic"
        name="Josh Cirre"
        title="Worship Arts Pastor"
      />
      <StaffBox
        imageUrl={Kyle}
        imageAlt="Kyle's Profile Pic"
        name="Kyle Gallagher"
        title="Student Ministry & Sports Director"
      />
      <StaffBox
        imageUrl={Jessica}
        imageAlt="Jessica's Profile Pic"
        name="Jessica Baker"
        title="Kid's Ministry Director"
      />
      <StaffBox
        imageUrl={Alicia}
        imageAlt="Alicia's Profile Pic"
        name="Alicia Micheli"
        title="Preschool Associate"
      />
      <StaffBox
        imageUrl={Krystal}
        imageAlt="Krystal's Profile Pic"
        name="Krystal Goodson"
        title="Elementary Associate"
      />
      <StaffBox
        imageUrl={Adam}
        imageAlt="Adam's Profile Pic"
        name="Adam Wade"
        title="AV Director"
      />
      <StaffBox
        imageUrl={Robbie}
        imageAlt="Robbie's Profile Pic"
        name="Robbie Gittings"
        title="First Impressions Director"
      />
    </Grid>
    <PageHeaders
      css={css`
        margin-top: 6rem;
      `}
    >
      Cross Church Central Support
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText></ParagraphText>
    <HeaderText></HeaderText>
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
      gap={10}
    >
      <StaffBox
        imageUrl={Jackie}
        imageAlt="Jackie's Profile Pic"
        name="Jackie Allen"
        title="Lead Pastor"
      />
      <StaffBox
        imageUrl={Chad}
        imageAlt="Chad's Profile Pic"
        name="Chad Kritenbrink"
        title="Executive Pastor"
      />
      <StaffBox
        imageUrl={Debra}
        imageAlt="Debra's Profile Pic"
        name="Debra Toles"
        title="Cross Church Administrator"
      />
      <StaffBox
        imageUrl={Ed}
        imageAlt="Ed's Profile Pic"
        name="Ed Wales"
        title="Facilities Manager"
      />
      <StaffBox
        imageUrl={Florence}
        imageAlt="Florence's Profile Pic"
        name="Florence Brown"
        title="Financial Administrative Assistant"
      />
    </Grid>
    <PageHeaders
      css={css`
        margin-top: 6rem;
      `}
    >
      Cross Church Elders
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText></ParagraphText>
    <HeaderText></HeaderText>
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
      gap={10}
    >
      <StaffBox
        imageUrl={Jim}
        imageAlt="Jim's Profile Pic"
        name="Jim Godfrey"
        title="Elder"
      />
      <StaffBox
        imageUrl={Michael}
        imageAlt="Michael's Profile Pic"
        name="Michael Fox"
        title="Elder"
      />
      <StaffBox
        imageUrl={Jackson}
        imageAlt="Jackson's Profile Pic"
        name="Jackson Nay"
        title="Elder"
      />
      <StaffBox
        imageUrl={Lyle}
        imageAlt="Lyle's Profile Pic"
        name="Lyle Harrenstein"
        title="Elder"
      />
    </Grid>
  </Layout>
)

export default OurLeadership
